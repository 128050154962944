// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
jQuery.ajaxSetup({ cache: true });
window.jQuery = $;
window.$ = $;

require('shared/modal')
import 'controllers';
require('home/navbar')

require('shared/notify')
require('shared/init')
require('home/shop_show')
require('libs/scroll_center')
require('shared/favorite_shops')
require('shared/flutter')
import { flashMessage } from '../shared/notify';
window.moment = require('moment');
import 'moment/locale/fr';

console.log('projectId', process.env.projectId)

//import { loadCoords } from '../home/map';
import { jwtChecker } from 'shared/jwt_parser';

$(document).on('turbolinks:load', function() {
  jwtChecker()
  if(localStorage.getItem('flashMessage')) {
    flashMessage('', localStorage.getItem('flashMessage'), 'warning')
    localStorage.removeItem('flashMessage')
  }
  if($(window).width() > 900) {
    window.location = `${process.env.callbackEndPoint}${window.location.pathname}${window.location.search}`
  }
  $('body').removeClass('loading')
  if($('#map').length) {
    loadCoords()
  }
})

$(document).on('click', '.select_delivery_params', (e) => {
  $.get('/cart/edit_delivery')
})

$(document).on("turbolinks:before-cache", function() {
  $('body').removeClass('loading')
})



$(document).on('click', 'body', function(e) {
  var container = $("#cart_content");
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0 && $(e.target).get(0).id != 'icon_cart')
  {
    container.hide();
  }
})


